import React from 'react';

import featuredImage from '@images/header-images/react-development-featured-image.jpg';
import imageInAboutUsSection from '@images/react-development/about-us.png';
import aboutUsImage from '@images/react-development/about-us.png';
import imgOnDesktop from '@images/react-development/lets-talk-on-desktop.png';

import * as styles from './payload.module.scss';

export const headerProps = {
  title: 'React Development Services',
  showImageOnMobile: true,
  src: 'react-development-header-image.webp',
  srcSmall: 'codilime_golang_header_small.png',
  displayTopBanner: false,
  className: styles.header,
};

export const seoProps = {
  title: 'React Development Services Company - CodiLime',
  description:
    "Want to build a project in React? Choose an experienced strategic partner. We've been developing software for tech-driven businesses since 2013.",
  featuredImage: featuredImage,
};

export const aboutUsData = {
  firstParagraph:
    'Want to create a stable and scalable application which also is able to maintain high performance? React is a solution that can meet your business needs and product requirements.',
  secondParagraph: (
    <>
      At CodiLime, we have been providing <span className="bold-text">React development services</span> for our clients
      since 2013, helping them create SEO-friendly applications with outstanding user interfaces (UI).
    </>
  ),
  buttonLabel: 'Start a React project with us',
  buttonHref: '#contact',
  imageOnRight: imageInAboutUsSection,
  imageAltText: 'React software development services',
  noImgOnMobile: true,
  spaceOnMobile: true,
  classNames: {
    customStylesButton: styles.aboutUsButton,
    customStylesSecondParagraph: styles.aboutUsSecondParagraph,
    customImgStyles: styles.aboutUsImgContainer,
    innerWrapper: styles.aboutUsInnerWrapper,
  },
};

export const imgSectionProps = {
  imgSrc: aboutUsImage,
  imgAltText: 'React software development services',
  classesData: {
    className: styles.downloadImage,
    classNames: { outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper },
  },
};

export const bulletedSectionData = {
  sectionTitle: 'How can our React developers help you?',
  sectionId: 'react-developers',
  subtitle:
    'Our highly skilled front-end engineers are ready to help you to achieve your business goals and build your competitive advantage. In particular, they will:',
  firstColumn: [
    'Build tailor-made React-based applications of any complexity.',
    'Deliver high-performance applications that are modern, user-friendly, responsive, and highly dependable.',
    'Design and develop quality front-end architecture that scales across large teams.',
    'Use state-of-art frameworks, libraries, and tools for building and deployment.',
    'Ensure the quality and reliability of the product by creating automated units, integration, and end-to-end test suites.',
  ],
  secondColumn: [
    'Build server-side rendered web applications.',
    'Support you in migrating your already existing applications to React.',
    'Maintain or review your project and advise on the best way forward.',
    'Cooperate with your engineering team, sharing expertise and best practices with them.',
    'Assist you in solving your ad-hoc queries about React and related technologies.',
    'Follow security requirements.',
  ],
};

export const letsTalkData = {
  firstHeading: (
    <>
      Don&apos;t miss the chance for a <span className="bold-font">better app.</span>
    </>
  ),
  buttonTitle: 'React now!',
  id: 'react-lets-talk',
  buttonHref: '#contact',
  imgAlt: 'React now',
  downloadImage: imgOnDesktop,
  classNames: {
    firstRow: styles.firstRow,
    title: styles.title,
    imageContainer: styles.imageContainer,
    buttonWithLink: styles.buttonWithLink,
    descriptionContainer: styles.descriptionContainer,
  },
};
