import readingLadyImage from './images/reading-lady_transparent.webp';

export const reactArticlesParagraph =
  'Our React developers share their knowledge on multiple platforms. You can choose between articles and video demos or dive deeper into detailed React-based projects. Learn more about how this solution can support your product.';

export const standardSectionProps = {
  title: 'What else can we say about React?',
  id: 'react-articles',
};

export const buttonListProps = {
  buttonsData: [
    {
      id: '1',
      title: 'MobX in React - learn the basics [Video Demonstration]',
      href: 'https://codilime.com/blog/mobx-in-react-learn-the-basics-video-demonstration/',
    },
    {
      id: '2',
      title: 'MobX in React – Scalable State Management',
      href: 'https://codilime.com/blog/mobx-in-react-scalable-state-management/',
    },
    {
      id: '3',
      title: 'Where are my keys? React keys',
      href: 'https://codilime.com/blog/where-are-my-keys-react-keys/',
    },
    {
      id: '6',
      title: 'Creating UX & UI of a portal for monitoring data visualization',
      href: 'https://codilime.com/case-studies/ux-ui-portal-for-monitoring-data-visualization/',
    },
    {
      id: '7',
      title: 'Building a web application for network monitoring in heterogeneous environments',
      href: 'https://codilime.com/case-study/building-web-application-for-network-monitoring-in-heterogeneous-environments/',
    },
  ],
};

export const imageData = {
  src: readingLadyImage,
  alt: 'Our expertise in programming in React',
};
