// extracted by mini-css-extract-plugin
export var aboutUsButton = "payload-module--about-us-button--7e34d";
export var aboutUsImgContainer = "payload-module--about-us-img-container--c532f";
export var aboutUsInnerWrapper = "payload-module--about-us-inner-wrapper--83f6e";
export var aboutUsSecondParagraph = "payload-module--about-us-second-paragraph--1ac0e";
export var buttonWithLink = "payload-module--button-with-link--7cccb";
export var descriptionContainer = "payload-module--description-container--9030b";
export var downloadImage = "payload-module--download-image--7fdd9";
export var firstRow = "payload-module--first-row--348b7";
export var header = "payload-module--header--6fb32";
export var imageContainer = "payload-module--image-container--0a9fb";
export var innerWrapper = "payload-module--inner-wrapper--bd62b";
export var outerWrapper = "payload-module--outer-wrapper--31230";
export var title = "payload-module--title--db59e";