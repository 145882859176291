import React from 'react';

import { reactArticlesParagraph, standardSectionProps, buttonListProps, imageData } from './payload';

import { SectionWithArticles } from '@pages-impl/shared/section-with-articles/SectionWithArticles';

import * as styles from './react-articles.module.scss';

export const ReactArticles = () => {
  return (
    <SectionWithArticles
      standardSectionProps={standardSectionProps}
      reactArticlesParagraph={reactArticlesParagraph}
      buttonListProps={buttonListProps}
      imageData={imageData}
      classNames={{ customButton: styles.customButton, customImage: styles.customImage }}
    />
  );
};
