import React from 'react';

import { OurApproachSection } from '@pages-impl/shared/our-approach/OurApproachSection';

import * as styles from './our-approach.module.scss';

export const OurApproach = () => {
  return (
    <OurApproachSection
      sectionTitle="React development — our approach"
      sectionId="our-approach"
      classNames={{
        boxesContainerStyles: styles.boxesContainerStyles,
        singleBoxDescriptionStyles: styles.singleBoxDescription,
      }}
    />
  );
};
