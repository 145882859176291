import React from 'react';

import SEO from '@commons/SEO';
import Layout from '@src/layouts';
import { Header } from '@commons/header/Header';
import { StandardSection } from '@commons/standard-section/StandardSection';
import { AboutUsSection } from '@pages-impl/shared/about-us-section/AboutUsSection';
import { BulletedSection } from '@commons/bulleted-section/BulletedSection';
import { TrustedBySection } from '@commons';

import { WhyUseReact, OurApproach, ReactArticles } from '@pages-impl/react-development';

import { LetsTalkSection } from '@pages-impl/shared/lets-talk-section/LetsTalkSection';

import {
  headerProps,
  seoProps,
  aboutUsData,
  imgSectionProps,
  bulletedSectionData,
  letsTalkData,
} from '@pages-impl/react-development/payload';

import * as styles from './react-development.module.scss';

export default function ReactDevelopmentServices(props) {
  const trustedByTitle = ' What do our clients & partners say about us?';
  const { imgSrc, imgAltText, classesData } = imgSectionProps;
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="React to get ahead. Contact us"
      oldPage
      {...props}
    >
      <SEO {...seoProps} />
      <Header {...headerProps} />
      <AboutUsSection {...aboutUsData} />
      <StandardSection {...classesData}>
        <img loading="lazy" src={imgSrc} alt={imgAltText} />
      </StandardSection>
      <WhyUseReact />
      <LetsTalkSection {...letsTalkData} />
      <BulletedSection {...bulletedSectionData} />
      <OurApproach />
      <TrustedBySection title={trustedByTitle} className={styles.trustedBySection} />
      <ReactArticles />
    </Layout>
  );
}
